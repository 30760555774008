<template>
    <div class="home">
        <!--海报-->
        <div class="flex-box">
            <div class="banner">
                <!-- <img
                    v-lazy="require('@/assets/home/banner.jpg')"
                    class="banner-img"
                /> -->
                <el-carousel height="500px" interval='5000' style="width:100%;" indicator-position="none">
                    <el-carousel-item>
                        <img v-lazy="require('@//assets/home/bannerList1.png')" alt="">
                    </el-carousel-item>
                    <el-carousel-item>
                        <img v-lazy="require('@//assets/home/bannerList2.png')"  alt="">
                    </el-carousel-item>
                    <el-carousel-item>
                        <img v-lazy="require('@//assets/home/bannerList3.png')" alt="">
                    </el-carousel-item>
                </el-carousel>
                <div class="data-box">
                    <div
                        class="data-outline"
                        v-for="(item, index) in info"
                        :key="item.title"
                    >
                        <div class="data-item">
                            <img v-lazy="item.icon" class="icon" />
                            <div>
                                <count-up
                                    start-value="1"
                                    :end-value="item.num"
                                    duration="3"
                                    class="num"
                                ></count-up>
                                <div class="title">{{ item.title }}</div>
                            </div>
                        </div>
                        <div
                            class="data-line"
                            v-if="index !== info.length - 1"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <!--产品介绍-->
        <div class="product">
            <div class="title-box reveal">
                <div class="title1">康美心理·五大产品端</div>
                <div class="title2">
                    满足心理健康管理不同领域的敏捷化和个性化需求
                </div>
                <img
                    class="title-icon"
                    v-lazy="require('@/assets/home/title-icon.png')"
                />
            </div>
            
            <div class="product-box reveal">
                <div class="product-content revealContent">
                    <div>
                        <div class="product-title">
                            互联网医院-SaaS云平台
                        </div>
                        <div class="product-intro">
                            康美心理SaaS云平台是中国数字化医疗与心理健康服务为一体的SaaS云平台，为
                            <span style="color: #f05b4f"> 互联网医院、实体的医疗机构、心理诊所、学校、企业、机关事业单位 </span>
                            等相关机构，提供高效、优质、全程、快捷的一站式服务;为
                            <span style="color: #f05b4f">
                                心理咨询机构或心理咨询师
                            </span>
                            提供 “1分钟”免费搭建网上心理工作室。
                        </div>
                    </div>
                    <div class="product-btn-box">
                        <div class="product-btn" @click="goto('/consult')">
                            了解更多
                        </div>
                    </div>
                </div>
                <img
                    v-lazy="require('@/assets/home/info.png')"
                    class="product-img4 revealImg"
                />
            </div> 
            <div class="product-box reveal">
                <div class="product-content revealContent">
                    <div>
                        <div class="product-title">
                            门诊测评-临床心理量表云平台
                        </div>
                        <div class="product-intro">
                            上百种常用专业
                            <span style="color: #f05b4f"> 临床心理量表 </span>
                            电子化、数据化，
                            <span style="color: #f05b4f">
                                免购专用测试设备
                            </span>
                            ，扫码测试，挂号、测评同步进行，快速、高效出具专业报告，为每位来访者建立专门的心理档案，提高门诊诊疗效率和服务满意度，构建私域云平台。
                        </div>
                    </div>
                    <div class="product-btn-box">
                        <div class="product-btn" @click="goto('/consult')">
                            了解更多
                        </div>
                    </div>
                </div>
                <img
                    v-lazy="require('@/assets/home/product4.png')"
                    class="product-img4 revealImg"
                />
            </div>
            <div class="product-box reveal">
                <div class="product-content revealContent">
                    <div>
                        <div class="product-title">
                            青少年心理成长监测-校园服务
                        </div>
                        <div class="product-intro">
                            为学校和教育主管单位建立学生
                            <span style="color: #f05b4f">心理档案</span>
                            ，掌握每个学生的心理健康状况，
                            <span style="color: #f05b4f">动态检测</span>
                            学生心理变化情况，对有心理危机的学生早发现，早干预，防止极端情况发生，有效降低家庭、学校、社会风险。
                        </div>
                    </div>
                    <div class="product-btn-box">
                        <div class="product-btn" @click="goto('/school')">
                            了解更多
                        </div>
                    </div>
                </div>
                <img
                    v-lazy="require('@/assets/home/product2.png')"
                    class="product-img2 revealImg"
                />
            </div>
            <div class="product-box reveal">
                <img
                    v-lazy="require('@/assets/home/product1.png')"
                    class="product-img1 revealImg"
                />
                <div class="product-content revealContent">
                    <div>
                        <div class="product-title">
                            企业服务-为人事安全保驾护航
                        </div>
                        <div class="product-intro">
                            如何选人、用人、人事安全生产管理？
                            <span style="color: #f05b4f"> “人尽其才”</span>
                            关系着企业生产效率，
                            <span style="color: #f05b4f"> “人事安全” </span>
                            关乎着企业生存和发展。<br />
                            通过系列职业规划测评、员工心理健康监测、通用人才选拔、能力测评、领导力测评等，为人才招聘、人员培训、人员安置、考核评价、提干晋升、团队建设、安全生产提供科学依据，降低企业风险。
                        </div>
                    </div>
                    <div class="product-btn-box">
                        <div class="product-btn" @click="goto('/company')">
                            了解更多
                        </div>
                    </div>
                </div>
            </div>
            <div class="product-box reveal">
                <img
                    v-lazy="require('@/assets/home/product3.png')"
                    class="product-img3 revealImg"
                />
                <div class="product-content revealContent">
                    <div>
                        <div class="product-title">机关事业单位在综合服务</div>
                        <div class="product-intro">
                            政府、事业单位专用的
                            <span style="color: #f05b4f"> 多级联动 </span>
                            心理健康网络平台，基于
                            <span style="color: #f05b4f"> 公务人员 </span>
                            心理的发展特点，通过对公务人员个体的能力、自我、适应、人际关系、智力、职业生涯等方面进行全面评估。
                        </div>
                    </div>
                    <div class="product-btn-box">
                        <div class="product-btn" @click="goto('/government')">
                            了解更多
                        </div>
                    </div>
                </div>
            </div>
            
        </div>
        <!--解决方案-->
        <div class="scheme">
            <div class="title-box reveal">
                <div class="title1">行业内权威平台方案，多行业、全场景服务</div>
                <div class="title2">
                    满足心理健康监测管理不同领域应用的敏捷化和个性化需求
                </div>
                <img
                    class="title-icon"
                    v-lazy="require('@/assets/home/title-icon.png')"
                />
            </div>
            <div class="tab-box reveal">
                <div @mouseenter="tab = 1" class="tab-outline">
                    <div :class="['tab', tab === 1 ? 'hover' : '']">
                        行业解决方案
                    </div>
                </div>
                <div @mouseenter="tab = 2" class="tab-outline">
                    <div :class="['tab', tab === 2 ? 'hover' : '']">
                        场景解决方案
                    </div>
                </div>
            </div>
            <div class="reveal">
                <div class="scheme-box" v-show="tab == 1">
                    <div class="boxes">
                        <div
                            :class="[
                                'box',
                                index == scheme1.length - 1 ? '' : 'mRight',
                            ]"
                            v-for="(scheme, index) in scheme1"
                            :key="index"
                            @click="goto(scheme.url)"
                        >
                            <div
                                class="box-img"
                                :style="
                                    'background-image:url(' +
                                    scheme.imgUrl +
                                    ')'
                                "
                            ></div>
                            <div class="box-main revealContent">
                                <img v-lazy="scheme.logo" class="logo" />
                                <div>{{ scheme.name }}</div>
                                <div>{{ scheme.intro }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="scheme-box" v-show="tab == 2">
                    <div class="boxes">
                        <div class="box mRight" @click="goto(scheme2[0].url)">
                            <div
                                class="box-img"
                                :style="
                                    'background-image:url(' +
                                    scheme2[0].imgUrl +
                                    ')'
                                "
                            ></div>
                            <div class="box-main">
                                <div>{{ scheme2[0].name }}</div>
                                <div>{{ scheme2[0].intro }}</div>
                            </div>
                        </div>
                        <div class="mRight">
                            <div
                                :class="[
                                    'box',
                                    'box2',
                                    i == 1 ? 'mBottom' : '',
                                ]"
                                v-for="i in 2"
                                :key="i"
                            >
                                <div
                                    class="box-img"
                                    :style="
                                        'background-image:url(' +
                                        scheme2[i].imgUrl +
                                        ')'
                                    "
                                ></div>
                                <div class="box-main">
                                    <div>{{ scheme2[i].name }}</div>
                                    <div>{{ scheme2[i].intro }}</div>
                                </div>
                            </div>
                        </div>
                        <div class="mRight">
                            <div
                                :class="[
                                    'box',
                                    'box2',
                                    i == 1 ? 'mBottom' : '',
                                ]"
                                v-for="i in 2"
                                :key="i"
                                @click="goto(scheme2[i + 2].url)"
                            >
                                <div
                                    class="box-img"
                                    :style="
                                        'background-image:url(' +
                                        scheme2[i + 2].imgUrl +
                                        ')'
                                    "
                                ></div>
                                <div class="box-main">
                                    <div>{{ scheme2[i + 2].name }}</div>
                                    <div>{{ scheme2[i + 2].intro }}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                class="box mBottom box3"
                                @click="goto(scheme2[5].url)"
                            >
                                <div
                                    class="box-img"
                                    :style="
                                        'background-image:url(' +
                                        scheme2[5].imgUrl +
                                        ')'
                                    "
                                ></div>
                                <div class="box-main">
                                    <div>{{ scheme2[5].name }}</div>
                                    <div>{{ scheme2[5].intro }}</div>
                                </div>
                            </div>
                            <div class="more-box">
                                <img
                                    v-lazy="
                                        require('@/assets/home/s2-icon.png')
                                    "
                                    class="more-icon"
                                />
                                <div>获取我的专属方案</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--平台优势-->
        <div class="advantage">
            <div class="title-box reveal">
                <div class="title1">康美心理·平台优势</div>
                <div class="title2">
                    权威专家审定，量表种类齐全，科学数据分析
                </div>
                <img
                    class="title-icon"
                    v-lazy="require('@/assets/home/title-icon.png')"
                />
            </div>
            <div class="advantage-boxes">
                <div class="advantage-box revealAdvantage1">
                    <div class="advantage-top">
                        <div class="flex-box">
                            <img
                                class="advantage-logo"
                                v-lazy="
                                    require('@/assets/home/advantage-logo1.png')
                                "
                            />
                            <div class="advantage-title">
                                量表种类齐全，行业应用广泛
                            </div>
                        </div>
                        <div class="advantage-intro">
                            联合专家团队共同研发，
                            <span style="color: #f05b4f">常模本土化</span>
                            ，量表种类、数量丰富，满足不同行业、应用场景的多样化需求。
                        </div>
                    </div>
                    <div class="advantage-bottom">
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                <span style="color: #f05b4f"> 100+套 </span>
                                专业心理测评量表
                            </div>
                        </div>
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                涵盖心理健康、情绪、人格、职业、人际关系等
                                <span style="color: #f05b4f"> 20多个维度 </span>
                            </div>
                        </div>
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                心理专家团队，多年研究、专业修订、规范应用和整合
                            </div>
                        </div>
                    </div>
                </div>
                <div class="advantage-box revealAdvantage2">
                    <div class="advantage-top">
                        <div class="flex-box">
                            <img
                                class="advantage-logo"
                                v-lazy="
                                    require('@/assets/home/advantage-logo2.png')
                                "
                            />
                            <div class="advantage-title">
                                专业测评报告，大数据分析挖掘
                            </div>
                        </div>
                        <div class="advantage-intro">
                            提供强大的样本数据的统计与分析功能，自动生成
                            <span style="color: #f05b4f">
                                个体报告和团体报告
                            </span>
                            ，提供针对性的指导建议。
                        </div>
                    </div>
                    <div class="advantage-bottom">
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                即刻生成个体报告，专家汇总团体报告
                            </div>
                        </div>
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                针对性结果解释、
                                <span style="color: #f05b4f">
                                    分析图解、指导建议
                                </span>
                            </div>
                        </div>
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                自动筛选，多维度分析挖掘，
                                <span style="color: #f05b4f">
                                    心理危机智能预警
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="advantage-box revealAdvantage3">
                    <div class="advantage-top">
                        <div class="flex-box">
                            <img
                                class="advantage-logo"
                                v-lazy="
                                    require('@/assets/home/advantage-logo3.png')
                                "
                            />
                            <div class="advantage-title">
                                支持多端应用，管理方便高效
                            </div>
                        </div>
                        <div class="advantage-intro">
                            适用于
                            <span style="color: #f05b4f">
                                政企单位、企业、学校、医院门诊、心理咨询室
                            </span>
                            等多个行业应用环境，管理更方便高效。
                        </div>
                    </div>
                    <div class="advantage-bottom">
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                支持
                                <span style="color: #f05b4f">
                                    PC、移动、平板端
                                </span>
                                使用，无缝对接微信平台
                            </div>
                        </div>
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                云端架构，支持
                                <span style="color: #f05b4f"> 10000人 </span>
                                同时在线测试
                            </div>
                        </div>
                        <div class="flex-start-box">
                            <div class="advantage-shape"></div>
                            <div class="advantage-intro">
                                双向加密技术，
                                <span style="color: #f05b4f">
                                    保障数据安全，
                                </span>
                                保密可靠
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--量表-->
        <div class="projects">
            <div class="title-box reveal">
                <div class="title1">全行业专业量表库</div>
                <div class="title2">
                    100+套专业心理测评量表，涵盖心理健康、情绪、人格、职业、人际关系等20多个维度
                </div>
                <img
                    class="title-icon"
                    v-lazy="require('@/assets/home/title-icon.png')"
                />
            </div>
            <div class="project-box reveal">
                <div
                    v-for="project in projects"
                    :key="project.id"
                    class="project"
                >
                    <img v-lazy="project.imgProjectMain" class="project-img" />
                    <div class="project-name">{{ project.name }}</div>
                    <div class="project-intro">{{ project.intro }}</div>
                </div>
            </div>
        </div>
        <!--合作伙伴-->
        <div class="partner">
            <div class="title-box reveal">
                <div class="title1">超过1000家企业客户正在使用康美心理平台</div>
                <div class="title2">
                    康美心理以超前的研发理念、严谨的研发态度、专业的客户服务，赢得全国众多企业客户喜爱
                </div>
                <img
                    class="title-icon"
                    v-lazy="require('@/assets/home/title-icon.png')"
                />
            </div>
            <img
                v-lazy="require('@/assets/home/org1.png')"
                class="org-img1 reveal"
            />
            
        </div>
    </div>
</template>

<script>
    import CountUp from 'vue-countupjs'
    import scrollReveal from 'scrollreveal'
    export default {
        name: 'Home',
        data () {
            return {
                info: [
                    {
                        num: '128347',
                        title: '服务用户',
                        icon: require('@/assets/home/info-user.png')
                    },
                    {
                        num: '5945',
                        title: '测评场次',
                        icon: require('@/assets/home/info-sessions.png')
                    },
                    {
                        num: '537586',
                        title: '测评数量',
                        icon: require('@/assets/home/info-eval.png')
                    },
                    {
                        num: '1437',
                        title: '合作伙伴',
                        icon: require('@/assets/home/info-part.png')
                    }
                ],
                tab: 1,
                projects: [
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/26-qzgx.jpg",
                        intro: "康奈尔医学指数（CMI）自评式健康问卷，最早于1944年用于军队征兵时的健康筛查，以后也用于流行病学调查，作为临床检查的辅助手段。可在短时间内收集到大量有关医学及心理学的资料，起到标准化的健康检查作用。分为18个部分195个项目，其中51个项目是与精神活动有关的情绪、情感和行为方面的问题。涉及四方面内容：（1）躯体症状；（2）家族史和既往史；（3）一般健康和习惯；（4）精神症状。",
                        name: "康奈尔医学指数量表"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/28-DISC.jpg",
                        intro: "一、DISC简介    20世纪20年代，美国心理学家威廉·莫尔顿·马斯顿创建了一个理论来解释人的情绪反应，在此之前，这种工作主要局限在对于精神病患者或精神失常人群的研究，而马斯顿博士则希望扩大这个研究范围，以运用于心理健康的普通人群，因此，马斯顿博士将他的理论构建为一个体系，即―TheEmotionsofNormalPeople“正常人的情绪”。\r\n为了检验他的理论，马斯顿博士需要采用某种心理测评的方式来衡量人群的情绪反映－“人格特征”，因此，他采用了四个他认为是非常典型的人格特质因子，即Dominance－支配，Influence－影响，Steady－稳健，以及Compliance－服从。而DISC，正是代表了这四个英文单词的首字母。在1928年，马斯顿博士正是在他的“正常人的情绪”一书中，提出了DISC测评，以及理论说明。目前，DISC理论已被广泛应用于世界500强企业的人才招聘，历史悠久、专业性强、权威性高。\r\n二、测试结果的使用说明：    计算你的各项得分，超过10分称为显性因子，可以作为性格测评的判断依据。低于10分称为隐性因子，对性格测评没有实际指导意义，可以忽略。如果有两项及以上得分超过10,说明你同时具备那两项特征。",
                        name: "DISC性格测试"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/30-ucla.jpg",
                        intro: "【孤独感介绍】\r\n孤独感是一种封闭的心理状态，因感到自身与外界隔绝或被外界排斥而产生的苦闷心情，人在渴望交往与实际交往情况不佳时容易产生孤独感。心理学家指出，内向者对自我世界的过度关注极易引发孤独感。内向的人不喜欢、不习惯表达自己；或常常缺乏勇气，担心自己的想法不受重视或不被肯定，进而选择缄默，因此难免孤独。\r\n孤独者因为采用消极的交往方式，并缺乏必要的社交技能，而难以与他人建立亲密的友谊。与这些人交往常常让人感到不愉快，于是他们很难建立有助他们发展社交技能的人际关系。因而难以摆脱孤独。心理学家认为，通过基本社交技能的训练，可以使孤独者走出孤独的恶性循环，并已广泛应用于心理咨询与治疗的实践中。这些方案提供一定的希望，即孤独不必陷入抑郁的恶性循环之中而不能自拔。",
                        name: "ucla孤独量表"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/39-txgx.jpg",
                        intro: "本测验可用来帮助中学生了解自己与朋友的关系， 了解自己在与同学相处过程中存在哪些典型的行为问题。由28 个陈述句组成，每个几子后面列有“是”和“否”两种答案。请认真阅读每一个句子，并对照自己的实际情况，作出是或否的判断，然后将“是”或“否”圈上。",
                        name: "同学关系测验问卷"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/45-grcs.jpg",
                        intro: "处事得当老练通常是个性成熟与否的评定标准，请你根据自己的真实情况，回答以下问题。请注意本测试没有标准答案，只需要回答你真实的做法或想法。",
                        name: "个人成熟度测试"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/42-hyaq.jpg",
                        intro: "有很多人觉得单身的日子孤独寂寞，看到身边结了婚的朋友很和睦幸福的样子，就会很羡慕，寄希望于婚姻，希望婚姻能够帮助自己赶走孤独寂寞的滋味，能够带给自己足够的安全感。婚姻是一份爱和责任，能够带给每个人的感受不同，至于安全感，当然也是不一样的。",
                        name: "婚姻安全感测试"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/44-zsxg.jpg",
                        intro: "面具下的个人才最真实。社会心理学家笑侃每个人的家里都置有一个面具衣柜，以扮演不同的角色，应对不同的需要和责任。所以，当你摘下所有的面具，你的性格呈现哪种特质呢？本题告诉你答案。",
                        name: "真实性格测试"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/46-lybang.jpg",
                        intro: "该问卷是了解您的婚姻状态的，虽然它不能预测您的婚姻是否成功，但可以发现婚姻中可能存在和需要解决的问题，有助于得到专家的指导。希望您如实填写，不要征求他人的意见，独立完成。请注意：条目中的“我们”是指您和您的伴侣。对每一个条目均进行5级评定，具体如下：1.确实是这样；2.可能是这样；3.不同意也不反对；4.可能不是这样；5.确实不是这样",
                        name: "你对另一半满意吗？"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/51-MBTI.jpg",
                        intro: "MBTI提供的性格类型描述仅供测试者确定自己的性格类型之用，性格类型没有好坏，只有不同。每一种性格特征都有其价值和优点，也有缺点和需要注意的地方。清楚地了解自己的性格优劣势，有利于更好地发挥自己的特长，而尽可能的在为人处事中避免自己性格中的劣势，更好地和他人相处，更好地作重要的决策。",
                        name: "MBTI职业倾向测验"
                    },
                    {
                        imgProjectMain: "https://mpt.uelink.com.cn/upload/imgProjectMain/37-shhb.jpg",
                        intro: "社交回避及苦恼分别指回避社会交往的倾向及身临其境时的苦恼感受。回避是一种行为表现，苦恼则为情感反应。",
                        name: "社交回避及苦恼量表"
                    }
                ],
                schools: [
                    '连州市第二中学', '清远市清城区横荷街中心小学', '连州市慧光中学', '汕头市大华二小', '汕头市澄海澄华中学', ' 广东汕头华侨中学', '廉江市青平中学', '肇庆市高要区星科学校', '肇庆市高要区南岸中心小学', '肇庆市高要区第一中学', '华城镇中心小学', '兴宁市第十小', '五华县桥江中学', '龙川县第一小学', '二九二学校', '东源县第二小学', '珠海市斗门区六乡中学', '福州市罗星中心小学', '福州市长乐区城关中学', '福建省福州市则徐中学', '长汀县大同中心学校', '龙岩一中锦山学校', '连城县第二实验小学', '颜厝中心小学', '漳州市龙海区第四中学', '福建省漳州第一职业中专学校', '仙游县城东中心小学', '仙游县第一道德中学', '彭泽县第一中学', '彭泽县芙蓉中学'
                ],
                // 板块放置
                scheme1: [
                    {
                        name: '企业',
                        intro: '心理评估',
                        imgUrl: require('@/assets/home/s1-img1.png'),
                        logo: require('@/assets/home/s1-logo1.png'),
                        url: '/company'
                    },
                    {
                        name: '校园师生',
                        intro: '心理评估',
                        imgUrl: require('@/assets/home/s1-img2.png'),
                        logo: require('@/assets/home/s1-logo2.png'),
                        url: '/school'
                    },
                    {
                        name: '政企单位',
                        intro: '心理评估',
                        imgUrl: require('@/assets/home/s1-img3.png'),
                        logo: require('@/assets/home/s1-logo3.png'),
                        url: '/government'
                    }, {
                        name: '心理门诊',
                        intro: '线上评估',
                        imgUrl: require('@/assets/home/s1-img4.png'),
                        logo: require('@/assets/home/s1-logo4.png'),
                        url: '/consult'
                    },

                ],
                scheme2: [
                    {
                        name: '企业周期性',
                        intro: '员工心理监测',
                        imgUrl: require('@/assets/home/s2-img1.png'),
                        url: '/company'
                    },
                    {
                        name: '企业人才招聘',
                        intro: '心理评估',
                        imgUrl: require('@/assets/home/s2-img2.png'),
                        url: '/company'
                    },
                    {
                        name: '校园学生',
                        intro: '心理监测',
                        imgUrl: require('@/assets/home/s2-img3.png'),
                        url: '/school'
                    },
                    {
                        name: '政企招聘',
                        intro: '心理评估筛选',
                        imgUrl: require('@/assets/home/s2-img4.png'),
                        url: '/government'
                    },
                    {
                        name: '校园老师招聘',
                        intro: '评估筛查',
                        imgUrl: require('@/assets/home/s2-img4.png'),
                        url: '/school'
                    },
                    {
                        name: '心理门诊',
                        intro: '日常使用诊断',
                        imgUrl: require('@/assets/home/s2-img5.png'),
                        url: '/consult'
                    },
                ],
                scrollReveal: new scrollReveal()
            }
        },
        components: {
            CountUp
        },
        mounted () {
            this.getReveal();
        },
        computed: {
            schoolText () {
                let text = '';
                for (let i = 0; i < this.schools.length; i++) {
                    text = text + this.schools[i] + ' ';
                }
                return text;
            }
        },
        methods: {
            goto (path) {
                this.$router.replace(path)
            },
            getReveal () {
                let animation = {
                    duration: 1000,
                    reset: false,
                    mobile: false,
                    opacity: 0,
                    easing: 'ease-in-out',
                    enter: 'bottom',
                    distance: '40px'
                }
                let setReveal = (className, delay) => {
                    let revealAnimation = JSON.parse(JSON.stringify(animation));
                    revealAnimation['delay'] = delay;
                    this.scrollReveal.reveal(className, revealAnimation)
                }
                setReveal('.reveal', 0);
                setReveal('.revealImg', 500);
                setReveal('.revealContent', 800);
                setReveal('.revealAdvantage1', 200);
                setReveal('.revealAdvantage2', 500);
                setReveal('.revealAdvantage3', 800);
            }
        }
    }
</script>

<style scoped lang="scss">
    .home {
        width: 100%;
        background: #fff;

        .title-box {
            padding-top: 30px;
            @include flex($direction: column, $justify: center);
            background: url('~@/assets/home/title-bg.png');
            background-size: 365px 66px;
            background-repeat: no-repeat;
            background-position: top center;
            text-align: center;
            color: $title-color;
            .title1 {
                font-size: 48px;
                font-weight: bold;
                margin-bottom: 10px;
            }
            .title2 {
                font-size: 24px;
                margin-bottom: 40px;
            }
            .title-icon {
                width: 30px;
                height: 26px;
            }
        }

        .banner {
            @include flex($direction: column);
            width: 100%;
            margin-bottom: 110px;
            height: 600px;
            overflow: hidden;
            background-image: url('~@/assets/home/banner-bg.png');
            background-size: 100% 500px;
            background-repeat: no-repeat;
            background-color: #fff;

            .banner-img {
                width: 1920px;
                height: 500px;
            }

            .data-box {
                z-index: 99;
                width: 1200px;
                height: 130px;
                background: #ffffff;
                box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
                border-radius: 4px;
                margin-top: -65px;
                @include flex;
                .data-outline {
                    flex: 1;
                    @include flex;

                    .data-item {
                        width: 100%;
                        @include flex;
                        padding-left: 50px;

                        .icon {
                            width: 50px;
                            height: 50px;
                            margin-right: 20px;
                        }
                        .num {
                            color: #414246;
                            font-weight: bold;
                            font-size: 28px;
                        }
                        .title {
                            font-size: 16px;
                            color: #737377;
                            padding-top: 4px;
                        }
                    }

                    .data-line {
                        width: 1px;
                        height: 30px;
                        background: #d5dbff;
                    }
                }
            }
        }

        .product {
            @include flex($direction: column, $justify: center);
            .title-box {
                margin-bottom: 40px;
            }
            .product-box {
                @include flex($justify: center);
                background: #fafbfe;
                width: $width;
                height: 400px;
                margin-bottom: 20px;
                .product-img1 {
                    width: 416px;
                    height: 342px;
                    margin-right: 92px;
                }
                .product-img2 {
                    width: 404px;
                    height: 303px;
                    margin-left: 82px;
                }
                .product-img3 {
                    width: 545px;
                    height: 330px;
                    margin-right: 27px;
                }
                .product-img4 {
                    width: 535px;
                    height: 262px;
                    margin-left: 7px;
                }
                .product-content {
                    padding: 60px;
                    height: 100%;
                    @include flex($direction: column, $justify: space-between);
                    .product-title {
                        font-size: 24px;
                        color: $title-color;
                        font-weight: bold;
                        margin-bottom: 20px;
                    }
                    .product-intro {
                        font-size: 16px;
                        color: $intro-color;
                        line-height: 24px;
                        font-weight: 400px;
                        max-width: 480px;
                    }
                    .product-btn-box {
                        width: 100%;
                        @include flex;
                        .product-btn {
                            width: 120px;
                            height: 40px;
                            line-height: 40px;
                            border: 1px solid $color;
                            color: $color;
                            text-align: center;
                            font-size: 16px;
                            transition: all 0.5s;
                        }
                        .product-btn:hover {
                            background: $color;
                            color: #fff;
                            transform: translateY(-4px);
                        }
                    }
                }
            }
            .product-box:hover {
                box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
            }
        }

        .scheme {
            @include flex($direction: column, $justify: center);
            .title-box {
                margin: 100px 0 20px;
            }
            .tab-box {
                width: 1200px;
                height: 80px;
                @include flex;
                box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.16);
                margin-bottom: 50px;

                .tab-outline {
                    flex: 1;
                    @include flex($justify: center);
                    .tab {
                        position: relative;
                        height: 80px;
                        line-height: 80px;
                        font-size: 24px;
                        color: #414246;
                        font-weight: bold;
                        text-align: center;
                    }

                    .tab.hover {
                        color: $color;
                    }
                    .tab::after {
                        position: absolute;
                        content: '';
                        bottom: -1px;
                        left: 50%;
                        width: 0;
                        height: 4px;
                        border-radius: 40px;
                        -webkit-transform: translateX(-50%);
                        -ms-transform: translateX(-50%);
                        transform: translateX(-50%);
                        background-color: $color;
                        -webkit-transition: all 0.25s ease-in-out;
                        -o-transition: all 0.25s ease-in-out;
                        transition: all 0.25s ease-in-out;
                    }
                    .tab.hover::after {
                        width: 100%;
                    }
                }
            }

            .boxes {
                @include flex($justify: center);
                .box {
                    position: relative;
                    width: 288px;
                    height: 448px;
                    overflow: hidden;
                    .box-img {
                        width: 100%;
                        height: 100%;
                        background-size: 100% 100%;
                        transition: all 0.15s ease-in-out;
                    }

                    .box-main {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        @include flex($direction: column, $justify: center);
                        font-size: 24px;
                        color: #fff;
                        line-height: 36px;
                        z-index: 999;
                        .logo {
                            width: 100px;
                            height: 100px;
                            margin-bottom: 30px;
                        }
                    }
                }

                .box:hover .box-img {
                    transform: scale(1.2);
                }

                .box2 {
                    height: 216px !important;
                }

                .box3 {
                    height: 324px !important;
                }

                .mRight {
                    margin-right: 16px;
                }

                .mBottom {
                    margin-bottom: 16px;
                }
            }
            .more-box {
                @include flex;
                width: 288px;
                height: 108px;
                background: $color;
                border-radius: 4px;
                color: #fff;
                font-size: 24px;
                padding-left: 30px;

                .more-icon {
                    width: 36px;
                    height: 26px;
                    margin-right: 10px;
                }
            }
        }

        .advantage {
            @include flex($direction: column, $justify: center);
            padding: 100px 0 80px;
            .title-box {
                margin-bottom: 40px;
            }
            .advantage-boxes {
                width: $width;
                @include flex($justify: space-between);
                .advantage-box {
                    width: 390px;
                    height: 458px;
                    background: #fafbff;
                    border-radius: 4px;
                    .advantage-top {
                        padding: 40px 40px 30px 40px;
                        border-bottom: 1px solid #bac4ff;
                    }
                    .advantage-bottom {
                        padding: 30px 40px 40px 40px;
                    }
                    .advantage-logo {
                        width: 60px;
                        height: 60px;
                        margin-right: 20px;
                        flex-shrink: 0;
                    }
                    .advantage-title {
                        color: $title-color;
                        font-size: 24px;
                        line-height: 36px;
                        font-weight: 500;
                    }
                    .advantage-intro {
                        color: $intro-color;
                        font-size: 16px;
                        line-height: 24px;
                    }
                    .advantage-shape {
                        width: 10px;
                        height: 10px;
                        background: #ffffff;
                        border: 2px solid #536cfe;
                        border-radius: 50%;
                        margin-right: 10px;
                        flex-shrink: 0;
                        margin-top: 6px;
                    }
                    .flex-box {
                        @include flex;
                        margin-bottom: 20px;
                    }
                    .flex-start-box {
                        display: flex;
                        align-items: flex-start;
                        justify-content: flex-start;
                        margin-bottom: 20px;
                    }
                }

                .advantage-box:hover {
                    box-shadow: 0px 8px 30px rgba(121, 137, 248, 0.24);
                }
            }
        }

        .projects {
            width: 100%;
            background: #f7f7f7;
            padding: 80px 0;
            @include flex($direction: column, $justify: center);
            .title-box {
                margin-bottom: 60px;
            }
            .project-box {
                width: 1200px;
                @include flex;
                flex-wrap: wrap;
                .project {
                    width: 228px;
                    min-height: 270px;
                    background: #fff;
                    padding: 10px;
                    color: $intro-color;
                    margin-bottom: 30px;
                    border-radius: 4px;
                    box-shadow: 0 2px 14px 0 rgba(120, 144, 156, 0.1);
                    transition: all 0.2s;
                    .project-img {
                        width: 208px;
                        height: 156px;
                    }
                    .project-name {
                        color: $title-color;
                        font-size: 20px;
                        margin: 15px auto 8px;
                    }
                    .project-intro {
                        width: 100%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2;
                        -webkit-box-orient: vertical;
                    }
                }

                .project:not(:nth-of-type(5n)) {
                    margin-right: 15px;
                }

                .project:hover {
                    box-shadow: 0 2px 14px 0 rgba(120, 144, 156, 0.2);
                    transform: translateY(-8px);
                }
            }
        }

        .partner {
            padding-top: 80px;
            @include flex($direction: column, $justify: center);
            .title-box {
                margin-bottom: 60px;
            }
            .org-img1 {
                width: 763px;
                height: 363px;
                margin-bottom: 36px;
            }
            .org-box {
                width: 1200px;
                background: #ffffff;
                border: 1px solid #dfdfe2;
                border-radius: 4px;
                padding: 30px 30px;
                margin-bottom: 20px;

                .org-name {
                    font-size: 24px;
                    color: $title-color;
                    font-weight: 500;
                }

                .org-swiper {
                    width: 100%;
                    background: #f7f7f7;
                    border-radius: 4px;
                    margin-top: 20px;
                    padding: 0 20px;

                    .orgs {
                        position: relative;
                        width: 100%;
                        height: 90px;
                        overflow-x: hidden;
                    }

                    .node {
                        position: absolute;
                        left: 0;
                        display: inline-block;
                        height: 90px;
                        line-height: 90px;
                        white-space: nowrap;
                        color: #536cfe;
                        font-size: 24px;
                        font-weight: 500;
                        z-index: 999;
                        animation: wordsLoop 40s linear infinite;
                    }

                    @keyframes wordsLoop {
                        0% {
                            left: 0;
                        }
                        100% {
                            left: -500%;
                        }
                    }
                }

                .flex-start-box {
                    display: flex;
                    align-items: flex-start;
                    justify-content: flex-start;

                    .logo-name {
                        margin-top: 14px;
                    }
                }

                .flex-center-box {
                    @include flex($justify: flex-start);
                }

                .company-logos {
                    width: 942px;
                    height: 56px;
                    margin-left: 70px;
                }

                .government-logos {
                    width: 916px;
                    height: 112px;
                    margin-left: 90px;
                }

                .consult-logos {
                    width: 167px;
                    height: 33px;
                    margin-left: 70px;
                }
            }

            .org-box:hover {
                border-color: $color;
                .org-name {
                    color: $color;
                }
            }
        }
    }
</style>
